var site = site || {};

(function ($) {
  Drupal.behaviors.mppFilterSetV2 = {
    attach: function (context, settings) {
      var self = this;
      var $html = $('html');

      if (this.attached) {
        return;
      }
      this.attached = true;

      var $templates = $('.js-mpp-filter-set--v2', context);
      var debug = Boolean(settings.debug_mpp_filters);

      self.$header = $('.js-header', context);

      function updatePadding($container) {
        var $headerMain = self.$header.find('.js-site-header-main');
        var headerHeight;

        headerHeight = $headerMain.outerHeight(true);
        if (typeof headerHeight !== 'undefined') {
          headerHeight = parseInt(headerHeight, 10);
        } else {
          return;
        }

        /**
         * See if the current height is different than the stored height. If so, reset the
         * padding-top property on .site-content
         */
        if (headerHeight !== self.curHeight) {
          $container.css('padding-top', headerHeight + 'px');
          self.curHeight = headerHeight;
        }
      }

      // Helper function to populate the various filters supported with default values if empty
      function _add_filters(trans_key, key_prefix) {
        var rendered = '';
        var $filterCategory = $(
          '.js-mpp-filter-set__section--' + key_prefix + ' .js-mpp-filter-set-rendered',
          $templates
        );

        if (!$.trim($filterCategory.html()) && !_.isUndefined(site.translations[trans_key])) {
          _.each(site.translations[trans_key], function (obj) {
            obj.key = key_prefix + '-' + obj.key;
            rendered += site.template.get({
              name: 'filter_set_key',
              data: obj
            });
          });
          $filterCategory.prepend(rendered);
        }
      }

      _add_filters('product_categories', 'category');
      _add_filters('product_benefit', 'benefit');
      _add_filters('product_hairtype', 'hairtype');
      _add_filters('product_freefrom', 'freefrom');
      _add_filters('product_mppfilter', 'MPP_Filter');

      $templates.each(function () {
        var $this = $(this);
        var $filterBtn = $('.js-mpp-filter-set__button', $this);
        var $submitBtn = $('.js-mpp-filter-set__submit', $this);
        var $mobileBtn = $('.js-mpp-filter-set__mobile-cta-text', $this);
        var $arrowLeft = $('.js-mpp-filter-set__arrow--left', $this);
        var $mobilePanel = $('.js-mpp-filter-set__container, .js-mpp-filter-set__overlay', $this);
        var $closeBtn = $('.js-mpp-filter-set__close', $this);
        var $selectionsPanel = $('.js-mpp-filter-set__selections', $this);
        var $selectionsTags = $('.js-mpp-filter-set__selections-tags', $this);
        var $triggerSets = $('.js-mpp-filter-set__section', $this);
        var $filterTitle = $('.js-mpp-filter-set-title', $this);
        var $itemCountContainer = $('.js-mpp-filter-set__count-total', $this);
        var $filterCountContainer = $('.js-mpp-filter-set__filter-count', $this);
        var $recommendedCheckbox = $('.js-mpp-filter-set__recommended-checkbox', context);
        var $recommended = $('.js-mpp-filter-set__recommended', context);
        var $resetBtn = $('.js-mpp-filter-set-reset', context);
        var $mppContainer = $('.js-mpp', context);
        var $productGrid = $('.js-product-grid', $mppContainer);
        var $products = $('.js-product-grid-item', $productGrid);
        var $failContainer = $('.js-filter-no-matches', $mppContainer);
        var $filterSetLabel = $('.js-mpp-filter-set__label', $this);
        var $filterSetOverlay = $('.js-mpp-filter-set__background-overlay');
        var $filterSetBody = $('.js-mpp-filter-set__body', $this);
        var $mixItUpEl = $productGrid;
        var filterString = 'all';

        /* Helper function for adding the filter classes to the product items in the grid
           These get used by the filter() function */
        function _add_tags($product, filterKey, tags) {
          if (!tags) {
            return;
          }

          Object.keys(tags).forEach(function (key) {
            var tag = tags[key].replace(/-/u, '').replace(/\s+/u, '').replace(/\//u, '');

            if (tag) {
              $product.addClass('product-grid__item--filter-' + filterKey + '-' + tag);
            }
          });
        }

        // Go through each product in the grid to add the filtering classes
        $products.each(function () {
          var $thisProduct = $(this);

          _add_tags(
            $thisProduct,
            'category',
            _.map($thisProduct.data('attributes').tags.split(','), _.trim)
          );
          _add_tags($thisProduct, 'benefit', $thisProduct.data('benefit').tags);
          _add_tags($thisProduct, 'hairtype', $thisProduct.data('hairtype').tags);
          _add_tags($thisProduct, 'freefrom', $thisProduct.data('freefrom').tags);
          _add_tags($thisProduct, 'MPP_Filter', $thisProduct.data('mppfilter').tags);
        });

        // Loop through our filters and hide any without tags to filter-out the displayed products
        function updateFilters($items) {
          $filterBtn.each(function () {
            var $siblings;
            var $btn;
            var $filteredItems;
            var key;

            if (debug) {
              return;
            }

            $btn = $(this);
            key = $btn.data('filter');
            $filteredItems = $items.filter(key);
            if ($filteredItems.length) {
              $btn.removeClass('hidden');
              // Display the Category drop-down if there is at least one options to display
              $btn.closest('.js-mpp-filter-set__section').removeClass('hidden');
            } else {
              $btn.addClass('hidden');
              $siblings = $btn.siblings().not('.hidden');
              // Hide the Category drop-down if there is not options to display
              if (!$siblings.length) {
                $btn.closest('.js-mpp-filter-set__section').addClass('hidden');
              }
            }
          });
        }

        // Deeplinking support
        function getUrlParameter(name) {
          name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
          var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
          var results = regex.exec(location.search);

          return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        }

        var urlParam = getUrlParameter('filter');
        var queryTag = [];
        var queryArray = [];
        var urlFilter = '';

        if (urlParam) {
          queryTag = urlParam.split(',');
          $.each(queryTag, function (index, tag) {
            var queryFilter = '.product-grid__item--benefit-' + tag;

            $filterBtn.filter('[data-filter="' + queryFilter + '"]').addClass('active');
            queryArray.push(queryFilter);
          });
          urlFilter = queryArray.join(', ');
          // Console.info(urlFilter);
        }

        // Grabs the filter selectors and joins them
        function filter(recommended) {
          var filtersByOr = [];
          var filtersByAnd = [];
          var constructedFilters = [];
          var recoStr = '.product-grid__item--filter-recommended';

          $filterBtn.filter('.active').each(function () {
            var $btn = $(this);

            if ($btn.closest('.js-mpp-filter-set__section--category').length) {
              filtersByOr.push($btn.data('filter'));
            } else {
              filtersByAnd.push($btn.data('filter'));
            }
          });

          if (recommended) {
            filtersByAnd.push(recoStr);
          } else {
            filtersByAnd = $.grep(filtersByAnd, function (val) {
              return val != recoStr;
            });
          }

          /*  With "or" logic, selectors are concatenated into a single comma-separated selector
             filterString = filtersByOr.join(', ') || 'all';
              With "and" logic, selectors are joined into a single compound selector
              filterString = (filtersByOr.join(', ') + filtersByAnd.join('')) || 'all'; */
          _.each(filtersByOr, function (filter) {
            constructedFilters.push(filter + filtersByAnd.join(''));
          });

          if (constructedFilters.length) {
            filterString = constructedFilters.join(', ');
          } else {
            filterString = filtersByAnd.join('') || 'all';
          }

          if ($mixItUpEl.mixItUp('isLoaded')) {
            if (filterString === 'all') {
              reset();
            } else {
              $mixItUpEl.mixItUp('filter', filterString);
              $resetBtn.removeClass('disabled');
              $this.addClass('mpp-filter-set--filtered');
              $productGrid.addClass('product-grid--filtered');
            }
          }
        }

        // Collect each filter
        $triggerSets.each(function () {
          var $triggers = $('.js-mpp-filter-set__button', this);

          $triggers.on('click', function (event) {
            event.preventDefault();
            $(this).toggleClass('active');
            var $recommendedProducts = $('.js-product.is-recommended-product', context);
            var $recommendedChecked = $('.js-mpp-filter-set__recommended-checkbox', context).prop(
              'checked'
            );

            if ($recommendedChecked && $recommendedProducts.length > 0) {
              filter(true);
            } else {
              filter();
            }
          });
        });

        // Show selections
        function selections() {
          var selectedTags = $('.js-mpp-filter-set__button.active')
            .map(function () {
              return $.trim($(this).text());
            })
            .get()
            .join(', ');

          $selectionsTags.text(selectedTags);
          if ($filterBtn.hasClass('active')) {
            $selectionsPanel.addClass('active').insertAfter($('.mpp-filter'));
          } else {
            $selectionsPanel.removeClass('active');
          }
        }

        // Reset filters
        function reset() {
          var $recommendedProducts = $('.js-product.is-recommended-product', context);
          var $recommendedChecked = $('.js-mpp-filter-set__recommended-checkbox', context).prop(
            'checked'
          );
          if ($recommendedChecked && $recommendedProducts.length > 0) {
            filterString = '.product-grid__item--filter-recommended';
            $mixItUpEl.mixItUp('filter', filterString);
          } else {
            filterString = 'all';
            $mixItUpEl.mixItUp('filter', filterString);
          }
          $filterBtn.removeClass('active');
          $productGrid.removeClass('product-grid--filtered');
          $selectionsPanel.removeClass('active');
          $this.removeClass('mpp-filter-set--filtered');
          $resetBtn.addClass('disabled');
        }

        // Close mobile menu
        function close() {
          $mobilePanel.hide();
          $filterSetBody.addClass('hidden');
          $filterSetOverlay.addClass('hidden');
          $html.removeClass('no-scroll');
          selections();
        }

        function calculateSectionCount() {
          $('.js-mpp-filter-set__text').each(function () {
            var $this = $(this);
            var $countSection = $this.children('.js-mpp-filter-set__section-count');
            var $value = $this
              .parent()
              .siblings('ul')
              .children('.js-mpp-filter-set__button.active').length;

            $countSection.text($value === 0 ? '' : ' (' + $value + ')');
          });
        }

        // Trigger filter results
        $submitBtn.on('click', function (event) {
          event.preventDefault();
          // $mixItUpEl.mixItUp('filter', filterString);
          close();
        });

        // Open menu
        $mobileBtn.on('click', function (event) {
          event.preventDefault();
          updatePadding($mobilePanel);
          $mobilePanel.show();
          $filterSetBody.removeClass('hidden');
          $html.addClass('no-scroll');
        });

        $filterTitle.on('click', function (event) {
          var $this = $(this);
          var $thisFilters = $this.siblings('.js-mpp-filter-set-rendered');

          if (!$thisFilters.hasClass('hide-filter')) {
            $thisFilters.addClass('hide-filter');
            $this.removeClass('filter-active');
          } else {
            $thisFilters.removeClass('hide-filter');
            $this.addClass('filter-active');
          }
        });

        $('.js-mpp-filter-set-title:first', context).trigger('click');

        $filterSetLabel.once().on('click', function (event) {
          if ($filterSetBody.hasClass('hidden')) {
            $filterSetBody.removeClass('hidden');
            $filterSetOverlay.removeClass('hidden');
            event.stopPropagation();
            $('body').on('click', function (event) {
              if ($(event.target).closest('.js-mpp-filter-set__body').length === 0) {
                $filterSetBody.addClass('hidden');
                $filterSetOverlay.addClass('hidden');
              }
            });
          } else {
            $filterSetBody.addClass('hidden');
            $filterSetOverlay.addClass('hidden');
          }
        });

        $recommendedCheckbox.on('click', function (event) {
          var isChecked = $(this).prop('checked');

          if (isChecked) {
            $recommended.addClass('checked');
            filter(true);
          } else {
            $recommended.removeClass('checked');
            reset();
          }
        });

        $resetBtn.once().on('click', function (event) {
          event.preventDefault();
          if ($(this).hasClass('disabled')) {
            return;
          }
          reset();
        });

        $closeBtn.on('click', function (event) {
          event.preventDefault();
          close();
        });

        $arrowLeft.on('click', function (event) {
          event.preventDefault();
          close();
        });

        /* MixItUp Settings
           https://github.com/patrickkunka/mixitup/blob/v2/docs/configuration-object.md */
        var mixItUpArgs = {
          /* Animation: {
               effects: 'fade'
             }, */
          selectors: {
            target: '.js-product-grid-item'
          },
          controls: {
            enable: false
          },
          layout: {
            display: 'block'
          },
          load: {
            filter: urlFilter || 'all'
          }
        };

        if (!$mixItUpEl.hasClass('mixitup-initialized')) {
          $mixItUpEl.addClass('mixitup-initialized');
          var $grid = $('.js-product-grid', context);

          /* MixItUp Callbacks
             https://github.com/patrickkunka/mixitup/blob/v2/docs/events.md */
          $mixItUpEl
            .on('mixLoad', function () {
              $mppContainer.toggleClass('mpp-container--has-filters', true);
            })
            .on('mixEnd', function () {
              var $items = $(
                '.js-product-grid-item:visible:not(".product-grid__item--tout")',
                $mppContainer
              );

              $failContainer.hide();
              // Display # of filtered results
              $itemCountContainer.text($items.length);
              $filterCountContainer.text(
                $('.js-mpp-filter-set__button.active').length === 0
                  ? ''
                  : ' (' + $('.js-mpp-filter-set__button.active').length + ')'
              );
              calculateSectionCount();

              updateFilters($items);
              $mixItUpEl.addClass('mixitup-loaded');
              if ($mixItUpEl.data('deferredAnimation')) {
                $mixItUpEl
                  .data('deferredAnimation', false)
                  .data('mixItUp')
                  .setOptions({ animation: { enable: true } });
              }
            })
            .on('mixFail', function () {
              $failContainer.show();
            })
            .on('mixitup.initialize', function () {
              // Required in order to get the grid unstuck, due to OOS items being droped by ticket events
              $mixItUpEl.mixItUp('destroy');
              $mixItUpEl.find('.js-product-grid-item').attr('data-bound', '').data('bound', '');
              mixItUpArgs.animation = { enable: false };
              mixItUpArgs.load = { filter: filterString };
              $mixItUpEl.data('deferredAnimation', true).mixItUp(mixItUpArgs);
            });

          // Init mixItUp
          $mixItUpEl.mixItUp(mixItUpArgs);
          $grid.trigger('mixitup.initialize');
        }

        $(document).on('user.loaded', function (e, user) {
          if (user && user.first_name) {
            var $recommendedCheckbox = $('.js-mpp-filter-set__recommended', context);
            var $recommendedChecked = $('.js-mpp-filter-set__recommended-checkbox', context).prop(
              'checked'
            );
            var $username = $('.js-mpp-filter-set__recommended-username', context);
            var $recommendedCheckboxTooltip = $('.js-mpp-filter-set__recommended-tooltip', context);
            var tooltipContent = $recommendedCheckboxTooltip.data('tooltip');
            var $recommendedProducts = $('.js-product.is-recommended-product', context);

            if ($recommendedProducts.length && $recommendedChecked) {
              filter(true);
              $recommendedCheckbox.removeClass('hidden');
              $username.text(user.first_name);

              $recommendedCheckboxTooltip.tooltipster({
                animation: 'fade',
                arrow: true,
                contentAsHTML: true,
                interactive: true,
                position: 'bottom-right',
                speed: 250,
                content: tooltipContent,
                theme: 'tooltipster-aveda',
                trigger: !site.client.isMobile
                  ? 'hover'
                  : 'click' /** For mobile we may use click */,
                updateAnimation: false
              });
            }
          }
        });
      });
    },
    attached: false
  };
})(jQuery);
